<template>
  <section class="w-full">
    <header-register :showButtonView="true"
      >{{ $t("sectionProviders.titleProviders") }}
    </header-register>
    <main class="w-full">
      <ManageSuppliersCreate />
    </main>
  </section>
</template>

<script type="module">
import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageSuppliersCreate from "@/components/fornecedores/components/create/ManageSuppliersCreate.vue";

export default {
  name: "ProvidersEdit",
  components: {
    HeaderRegister,
    ManageSuppliersCreate,
  },
};
</script>
