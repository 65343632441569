<template>
  <ValidationObserver ref="observer" v-slot="{ validate }">
    <b-form @submit.stop.prevent="validate().then(CreateSupplier)">
      <div class="w-full flex flex-col justify-between">
        <section class="-mt-4">
          <b-tabs class="w-full">
            <b-tab title="Geral">
              <GeneralSupplierInformation v-model="viewModel" class="mt-4" />
            </b-tab>
            <b-tab title="Complemento">
              <SupplementaryInformationSupplier
                v-model="viewModel"
                class="mt-4"
              />
            </b-tab>
          </b-tabs>
        </section>
        <div class="flex">
          <span class="text-red-600"
            >Os campos com * são de preenchimento obrigatório</span
          >
        </div>
        <footer class="w-full flex items-center justify-start gap-5 my-10">
          <Button
            class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
            buttonText="Confirmar"
            type="submit"
          />
          <Button
            class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
            buttonText="Cancelar"
            :onClick="redirectUrl"
          />
        </footer>
        <Modal
          v-if="modalIsOpen"
          :modalIsOpen.sync="modalIsOpen"
          :closeModal="closeModal"
          :modalText="$t('commons.modalTextCreate')"
        />

        <Modal
          v-if="modalError"
          :modalIsOpen.sync="modalError"
          :closeModal="closeModal"
          :modalText="$t('commons.modalErrorCreate')"
        />
        <Modal
          v-if="modalErroSalvar"
          :modalIsOpen.sync="modalErroSalvar"
          :closeModal="closeModal"
          :modalText="$t('commons.modalErroSalvar')"
        />
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";

import GeneralSupplierInformation from "./GeneralSupplierInformation";
import SupplementaryInformationSupplier from "./SupplementaryInformationSupplier";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";
import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: "ManageSuppliersCreate",
  components: {
    BTabs,
    BTab,
    ValidationObserver,
    BForm,
    GeneralSupplierInformation,
    SupplementaryInformationSupplier,
    Button,
    Modal,
  },
  data() {
    return {
      modalIsOpen: false,
      modalError: false,
      modalErrorMessage: null,
      modalErroSalvar: false,
      viewModel: {
        Nome: null,
        NomeFantasia: null,
        CpfCnpj: null,
        InscEstadual: null,
        Email: null,
        endereco: {
          Cep: null,
          Rua: null,
          Numero: null,
          Complemento: null,
          BairroId: null,
          CidadeId: null,
          EstadoId: null,
        },
        telefone: [
          {
            DDD: null,
            Numero: null,
          },
          {
            DDD: null,
            Numero: null,
          },
        ],
        Banco: {
          BancoId: null,
          NomeDoBanco: null,
          Agencia: null,
          ContaCorrente: null,
        },
        Documentacao: {
          ResponsavelTecnico: null,
          AlvaraSanitario: null,
          AutEspecial: null,
          LicencaMapa: null,
          AutFuncionamento: null,
        },
        Farmacia: {
          CadastroFarmacia: null,
          Host: null,
          UsuarioFTP: null,
          Senha: null,
          Conta: null,
          ValorMinimo: null,
          FormaDePagamento: null,
          PrevisaoDeEntrega: null,
          Frete: null,
          Observacoes: null,
        },
      },
    };
  },
  created() {
    this.ObterSeletorPlanoContas();
  },
  methods: {
    redirectUrl() {
      return this.$router.push("/fornecedores");
    },
    openModal() {
      this.modalIsOpen = true;
    },
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalError = false;
        this.modalErroSalvar = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    async CreateSupplier() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.modalError = true;
        return;
      }
      this.RemoverMascaras();
      this.$http({
        url: "/fornecedor/novo",
        data: this.viewModel,
        method: "POST",
      })
        .then(() => {
          this.modalIsOpen = true;
          setTimeout(() => this.$router.push({ name: "fornecedores" }), 3000);
        })
        .catch(() => (this.modalErroSalvar = true));
    },
    RemoverMascaras() {
      this.viewModel.telefone = this.viewModel.telefone?.filter(
        (item) =>
          item.DDD != null &&
          item.DDD != "" &&
          item.Numero != null &&
          item.Numero != ""
      );
      if (this.viewModel.telefone?.length == 0) this.viewModel.telefone = null;
      if (
        this.viewModel.Farmacia.ValorMinimo == null ||
        this.viewModel.Farmacia.ValorMinimo == ""
      )
        this.viewModel.Farmacia.ValorMinimo = 0;
      if (this.viewModel.endereco.Cep?.length == 0)
        this.viewModel.endereco.Cep = null;
      for (let index = 0; index < this.viewModel.telefone?.length; index++) {
        this.viewModel.telefone[index].DDD = this.viewModel.telefone[
          index
        ].DDD?.replace(/[^0-9,]*/g, "");
        this.viewModel.telefone[index].Numero = this.viewModel.telefone[
          index
        ].Numero?.replace(/[^0-9,]*/g, "");
      }
      this.viewModel.CpfCnpj = this.viewModel.CpfCnpj?.replace(/[^0-9,]*/g, "");
      this.viewModel.endereco.Cep = this.viewModel.endereco.Cep?.replace(
        /[^0-9,]*/g,
        ""
      );
      this.viewModel.Banco.Agencia = this.viewModel.Banco.Agencia?.replace(
        /[^a-z0-9]/gi,
        ""
      );
      this.viewModel.Banco.ContaCorrente =
        this.viewModel.Banco?.ContaCorrente?.replace(/[^a-z0-9]/gi, "");
    },
  },
};
</script>

<style></style>
